/*! Tag script - v1.1.0 - 2018-12-17
 * used for all tag related calls
 * by Sander Schat */

(function ($) {

  // Listen for TAG DELETION
  //
  $(".deleteTag").on('click', function () {

    var tagid = $(this).closest('tr').data('tagid');
    var tagRow = $("tr[data-tagid='" + tagid + "']");
    var tagDeleteButton = tagRow.find('td.deleteTag');


    confirm("Remove this tag? ("+tagid+")", "Please select 'Yes' if you are sure.", function () {
      // do something if confirmed

      // set spinner
      tagDeleteButton.html('<i class="fas fa-circle-notch fa-spin"></i>');

      // ajax the call
      $.post(vars.ajaxUrl, {
          action: 'delete_tag',
          tag: tagid,
          nonce: vars.non
        },
        function (r) {
          var result = $.parseJSON(r);

          if (result.result === 'success') {
            // delete this row
            $("tr[data-tagid='" + tagid + "']").fadeOut();

          } else if (result.result === 'error') {

            tagDeleteButton.html('<i class="fas fa-exclamation"></i>');

          } else if (result.result === 'limitreached') {

            tagDeleteButton.html('Min. 1 tag is required');

          } else {

            tagDeleteButton.html('<i class="fas fa-circle-notch fa-spin"></i>');

          }
        });
    });
  });

  /**
   * CONFIRM popup HELPER
   * @param title
   * @param message
   * @param callback
   */
  function confirm(title, message, callback) {
    // create your modal template
    var modal = '<div class="reveal small" id="confirmation">' +
      '<h5>' + title + '</h5>' +
      '<p class="">' + message + '</p>' +
      '<button class="btn btn__blue success yes">Yes</button>' +
      '<button class="btn btn-danger alert float-right" data-close>No</button>' +
      '</div>';
    // appending new reveal modal to the page
    $('body').append(modal);
    // registergin this modal DOM as Foundation reveal
    var confirmation = new Foundation.Reveal($('#confirmation'));
    // open
    confirmation.open();
    // listening for yes click

    $('#confirmation').children('.yes').on('click', function () {
      // close and REMOVE FROM DOM to avoid multiple binding
      confirmation.close();
      $('#confirmation').remove();
      // calling the function to process
      callback.call();
    });
    $(document).on('closed.zf.reveal', '#confirmation', function () {
      // remove from dom when closed
      $('#confirmation').remove();
    });

  }


  /**
   * listen for the confirmation of tags
   */
  $('#btnConfirmTag').on('click', function (e) {
    e.preventDefault();

    // reset
    $('#confirmResultError').hide();
    $('#confirmResultSucces').hide();
    $('#confirmResultLimitreached').hide();


    // show the spinner
    $(this).html('&nbsp;&nbsp;<i class="fas fa-circle-notch fa-spin"></i>&nbsp;&nbsp;');

    // ajax the call
    $.post(vars.ajaxUrl, {
        action: 'confirm_tag',
        tag: vars.tagkey,
        nonce: vars.non
      },
      function (r) {
        var result = $.parseJSON(r);

        if (result.result == 'maxlimit') {

          //update the button
          $('#btnConfirmTag').html('<i class="fas fa-exclamation"></i>');

          // show error message
          $('#confirmOpener').addClass('hide');
          $('#confirmResultLimitreached').hide().removeClass('hide').fadeIn();

        } else if (result.result == 'success') {

          //update the button
          $('#btnConfirmTag').html('<i class="fas fa-check"></i>');

          // show success message
          $('#confirmOpener').addClass('hide');
          $('#confirmResultSucces').hide().removeClass('hide').fadeIn();

        } else {

          console.log(result);

          //update the button
          $('#btnConfirmTag').html('Confirm your tag?');

          // show error message
          $('#confirmResultError').hide().removeClass('hide').fadeIn();
        }
      }
    );
  })
})(jQuery);


